/********** gallery **********/

/*** node ***/
.gallery.layout-center {
  padding-left: 0;
  padding-right: 0;
}

/*** grid ***/
.view-gallery {

  .cboxElement {
    display: inline-block;
    text-align: center;
    color: color(text);
    border: 1px solid color('gray-black');
    line-height: 0;

    &:hover,
    &:active,
    &:visited {
      color: color(text);
    }

    .gallery-img-wrapper {
      display: inline-block;
      position: relative;
      overflow: hidden;
      z-index: 1;
    }

    .gallery-img {
      img {
        display: block;
        padding: 0;
        margin: 0;
        width: 100%;
        height: auto;
      }
    }

    .gallery-text-center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      padding: 0 10px;
    }

    .gallery-text {
      position: absolute;
      background: color('white');
      background: rgba( color('white'), .89);
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transform: translateY(100%);
      transition: all .25s;

      h4 {
        @include type-layout(m, 1);
        @include margin-top(0, m);
        @include margin-bottom(0, m);
      }
      p {
        @include type-layout(s, 1);
        @include margin-top(0, 2);
        @include margin-bottom(0, 2);
        text-transform: uppercase;
      }
    }

    &:hover,
    &:active {
      .gallery-text {
        transform: translateY(0%);
        transition: all .25s;
      }
    }

  }

}
