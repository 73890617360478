/********** rotations **********/

.flexslider {
  margin: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;

  /* nav */
  &:hover,
  &:active {

      .flex-direction-nav {

        .flex-prev {
          left: 0;
          opacity: 1;
        }

        .flex-next {
          right: 0;
          opacity: 1;
        }

      }

  }

  .flex-direction-nav {
    position: relative;

    a {
      display: block;
      opacity: 1;
      text-indent: -9999px;
      height: 0;
      width: 0;

      &.flex-prev,
      &.flex-next {
        bottom: 10px;
        position: absolute;
        top: auto;
      }

      &.flex-prev {
        right: auto;
        left: 0;
        border-right: 26px solid color('white');
        border-bottom: 15px solid transparent;
        border-left: 15px solid transparent;
        border-top: 15px solid transparent;

        &:hover,
        &:active {
          border-right: 26px solid color('green');
        }

      }

      &.flex-next {
        right: 0;
        left: auto;
        border-right: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 26px solid color('white');
        border-top: 15px solid transparent;

        &:hover,
        &:active {
          border-left: 26px solid color('green');
        }

      }

      &::before,
      &::after {
          text-indent: -9999px;
      }

      &::before {
        content: '';
      }

      &::after {
        content: '';
      }
    }

  }

}
