// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

.header,
%header {
  @extend %clearfix;
  background: color('white');
  box-shadow: 0 5px 25px rgba(color('black'), .25);
  padding-top: 25px;
  padding-bottom: 15px;

  @include respond-to(l) {
    padding-top: 55px;
    padding-bottom: 40px;
  }

  // Wrapping link for logo.
  &__logo {
    float: left;
    margin: 0 10px 0 0;
    padding: 0;
    width: 100px;
    position: relative;
    z-index: 1;

    @include respond-to(xs) {
      width: 200px;
    }

    @include respond-to(l) {
      width: 250px;
    }

    @include respond-to(xl) {
      width: auto;
    }

    @include rtl() {
      float: right;
      margin: 0 0 0 10px;
    }
  }

  // Logo image.
  &__logo-image {
    vertical-align: bottom;
  }

  // Wrapper for website name and slogan.
  &__name-and-slogan {
    float: left;
  }

  // The name of the website.
  &__site-name {
    @extend %h1;
    margin: 0;
  }

  // The link around the name of the website.
  &__site-link {
    &:link,
    &:visited {
      color: color(text);
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // The slogan (or tagline) of a website.
  &__site-slogan {
    margin: 0;
  }

  // The secondary menu (login, etc.)
  &__secondary-menu {
    float: right;

    @include rtl() {
      float: left;
    }
  }

  // Wrapper for any blocks placed in the header region.
  &__region {
    // Clear the logo.
    clear: both;
  }
}


/********** custom **********/

/*** navigation region ***/
.region-navigation {
  @extend %clearfix;
  text-align: right;
  position: relative;

  /* .block-superfish */
  .block-superfish {
    position: absolute;
    bottom: 0;
    right: 0;

    .sf-menu {
      @extend %clearfix;
    }

  }
}

/*** fixed header ***/
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
}

/* offset header */
body {
  padding-top: 76px;
  @include respond-to(xs) {
    padding-top: 76px;
  }
  @include respond-to(l) {
    padding-top: 139px;
  }
  @include respond-to(xl) {
    padding-top: 156px;
  }
}
