/********** highlights **********/

/*** homepage ***/
.pane-gallery-panel-pane-6 {
  text-align: center;

  h2 {
    font-weight: lighter;
    text-transform: uppercase;
    letter-spacing: 3px;
    @include type-layout(m, 1);
    @include margin-top(1, m);
    @include margin-bottom(0, m);
  }

}
