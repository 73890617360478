/********** social **********/
#block-menu-menu-social {

  ul.menu {
    padding: 0;
    margin: 0;
    text-align: center;

    li {
      list-style-type: none;
      list-style-image: none;
      display: inline-block;

      a {
        font-size: 0;
        line-height: 0;
        text-decoration: none;
        display: block;
        padding: 10px;

        &::before {
          font-size:  50px;
          line-height: 50px;
        }

      }
    }

  }
}
