/********** main menu **********/
.region-navigation {

  .sf-menu {
    li.sf-depth-1 {
      a.sf-depth-1 {
        text-transform: uppercase;
        text-decoration: none;
        padding: 0 20px 0 0;

        @include respond-to(m) {
          @include type-layout(s, 1);
            padding: 0 20px 0 0;
        }

        @include respond-to(xxl) {
            @include type-layout(l, 1);
            padding: 0 40px 0 0;
        }

      }

      &.last {
        a.sf-depth-1 {
          padding-right: 0;
        }
      }
    }
  }

}


/********** mobile **********/
#sidr-wrapper-0 {
  float: right;
  #sidr-0-button {
    display: inline-block;
    text-decoration: none;
    border: 1px solid color(link);
    padding: 5px;

    &:hover,
    &:active {
      background-color: color(link-visited);
      border: 1px solid color(link-visited);
      color: color('white');
    }
  }
}
#sidr-0 {
  /* offset header */
  top: 76px;
}
