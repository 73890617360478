/********** footer **********/
.footer-wrapper {
  color: color('white');
  text-align: center;
  font-weight: lighter;
  padding-top: 25px;

  /*** text and links ***/
  * {
    color: color('white');;
    font-weight: lighter;
  }
  a {
    color: color('white');

    &:hover,
    &:active {
      color: color(link);
    }
  }

  /*** form ***/
  .webform-client-form {

    > div {
      @extend .row;
      @extend .expanded;
    }

    label {
      text-align: left;
    }

    input[type='submit'] {
      min-width: 100px;
    }

    input[type='text'],
    input[type='email'],
    textarea {
      width: 100%;
      color: color(text) !important;
    }

  }
}
