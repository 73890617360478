@font-face {
  font-family: 'icons';
  src: url("fonts/icons.eot?qg6pqp");
  src: url("fonts/icons.eot?qg6pqp#iefix") format("embedded-opentype"), url("fonts/icons.ttf?qg6pqp") format("truetype"), url("fonts/icons.woff?qg6pqp") format("woff"), url("fonts/icons.svg?qg6pqp#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]::before,
[class*=" icon-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fb:before {
  content: "\e900";
}

.icon-li:before {
  content: "\e901";
}

.icon-tw:before {
  content: "\e902";
}

* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: "Muli", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #464647;
  background-color: #474747;
}

body .header,
body .main-content {
  background-color: #fff;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #007c99;
  -webkit-text-decoration-skip: objects;
}

:visited {
  color: #007c99;
}

a:hover,
a:focus {
  color: #002933;
  outline: 0;
}

a:active {
  color: #c00;
  outline: 0;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

h1,
.header__site-name {
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: lighter;
}

h2 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h1, h2, h3, h4, h5, h6 {
  color: #707070;
}

@media all and (min-width: 40em) {
  h1.title-offset, .title-shift h1 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    float: right !important;
  }
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #464647;
}

blockquote {
  margin: 0 !important;
  font-style: italic;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #707070;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  background: #a0a0a0;
  text-transform: uppercase;
  letter-spacing: 3px;
  border: none;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #fff;
  background: #0072b9;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

/********** custom **********/
.webform-submit {
  display: block;
  margin: 0 auto;
}

[type='email'],
[type='text'],
[type='search'],
[type='tel'],
[type='url'],
[type='email'],
[type='password'],
[type='date'],
[type='time'],
[type='number'] {
  padding: 10px;
  border: 1px solid #a0a0a0;
}

[type='email']:hover, [type='email']:active, [type='email']:focus,
[type='text']:hover,
[type='text']:active,
[type='text']:focus,
[type='search']:hover,
[type='search']:active,
[type='search']:focus,
[type='tel']:hover,
[type='tel']:active,
[type='tel']:focus,
[type='url']:hover,
[type='url']:active,
[type='url']:focus,
[type='email']:hover,
[type='email']:active,
[type='email']:focus,
[type='password']:hover,
[type='password']:active,
[type='password']:focus,
[type='date']:hover,
[type='date']:active,
[type='date']:focus,
[type='time']:hover,
[type='time']:active,
[type='time']:focus,
[type='number']:hover,
[type='number']:active,
[type='number']:focus {
  border: 1px solid #0072b9;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #707070;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: lighter;
  text-transform: uppercase;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
  padding: 10px;
  border: 1px solid #a0a0a0;
}

textarea:hover, textarea:active, textarea:focus {
  border: 1px solid #0072b9;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

/********** default **********/
/*** padding ***/
.main-content {
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.75);
}

@media (min-width: 767px) {
  .main-content {
    padding-top: 40px;
  }
}

@media all and (min-width: 40em) {
  .panels-flexible-region-basic_page-center {
    padding-right: 20px !important;
  }
}

/*** title ***/
.title-shift .panels-flexible-basic_page {
  clear: both;
}

/********** two col **********/
@media screen and (min-width: 64em) {
  .panels-flexible-region-two_column_50_50-center {
    padding-right: 20px !important;
  }
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 767px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1165px;
}

@media (min-width: 767px) {
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.row, .footer-wrapper .webform-client-form > div {
  max-width: 62.5rem;
  margin-left: auto;
  margin-right: auto;
}

.row::before, .footer-wrapper .webform-client-form > div::before, .row::after, .footer-wrapper .webform-client-form > div::after {
  content: ' ';
  display: table;
}

.row::after, .footer-wrapper .webform-client-form > div::after {
  clear: both;
}

.row.collapse > .column, .footer-wrapper .webform-client-form > div.collapse > .column, .row.collapse > h1.title-offset, .footer-wrapper .webform-client-form > div.collapse > h1.title-offset, .title-shift .row.collapse > h1, .footer-wrapper .title-shift .webform-client-form > div.collapse > h1, .title-shift .footer-wrapper .webform-client-form > div.collapse > h1, .row.collapse > .columns, .footer-wrapper .webform-client-form > div.collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}

.row .row, .footer-wrapper .webform-client-form > div .row, .row .footer-wrapper .webform-client-form > div, .footer-wrapper .row .webform-client-form > div, .footer-wrapper .webform-client-form > div .webform-client-form > div {
  max-width: none;
  margin-left: -10px;
  margin-right: -10px;
}

.row .row.collapse, .footer-wrapper .webform-client-form > div .row.collapse, .row .footer-wrapper .webform-client-form > div.collapse, .footer-wrapper .row .webform-client-form > div.collapse, .footer-wrapper .webform-client-form > div .webform-client-form > div.collapse {
  margin-left: 0;
  margin-right: 0;
}

.row.expanded, .footer-wrapper .webform-client-form > div {
  max-width: none;
}

.row.expanded .row, .footer-wrapper .webform-client-form > div .row, .row.expanded .footer-wrapper .webform-client-form > div, .footer-wrapper .row.expanded .webform-client-form > div, .footer-wrapper .webform-client-form > div .webform-client-form > div {
  margin-left: auto;
  margin-right: auto;
}

.column, h1.title-offset, .title-shift h1, .columns {
  width: 100%;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.column:last-child:not(:first-child), h1.title-offset:last-child:not(:first-child), .title-shift h1:last-child:not(:first-child), .columns:last-child:not(:first-child) {
  float: left;
}

.column.end:last-child:last-child, h1.end.title-offset:last-child:last-child, .title-shift h1.end:last-child:last-child, .end.columns:last-child:last-child {
  float: left;
}

.column.row.row, h1.row.row.title-offset, .title-shift h1.row.row, .footer-wrapper .webform-client-form > div.column, .row.row.columns, .footer-wrapper .webform-client-form > div.columns {
  float: none;
}

.row .column.row.row, .footer-wrapper .webform-client-form > div .column.row.row, .row h1.row.row.title-offset, .footer-wrapper .webform-client-form > div h1.row.row.title-offset, .row .title-shift h1.row.row, .title-shift .row h1.row.row, .footer-wrapper .webform-client-form > div .title-shift h1.row.row, .title-shift .footer-wrapper .webform-client-form > div h1.row.row, .row .footer-wrapper .webform-client-form > div.column, .footer-wrapper .row .webform-client-form > div.column, .footer-wrapper .webform-client-form > div .webform-client-form > div.column, .row .row.row.columns, .footer-wrapper .webform-client-form > div .row.row.columns, .row .footer-wrapper .webform-client-form > div.columns, .footer-wrapper .row .webform-client-form > div.columns, .footer-wrapper .webform-client-form > div .webform-client-form > div.columns {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.small-1 {
  width: 8.33333%;
}

.small-push-1 {
  position: relative;
  left: 8.33333%;
}

.small-pull-1 {
  position: relative;
  left: -8.33333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  width: 16.66667%;
}

.small-push-2 {
  position: relative;
  left: 16.66667%;
}

.small-pull-2 {
  position: relative;
  left: -16.66667%;
}

.small-offset-1 {
  margin-left: 8.33333%;
}

.small-3 {
  width: 25%;
}

.small-push-3 {
  position: relative;
  left: 25%;
}

.small-pull-3 {
  position: relative;
  left: -25%;
}

.small-offset-2 {
  margin-left: 16.66667%;
}

.small-4 {
  width: 33.33333%;
}

.small-push-4 {
  position: relative;
  left: 33.33333%;
}

.small-pull-4 {
  position: relative;
  left: -33.33333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  width: 41.66667%;
}

.small-push-5 {
  position: relative;
  left: 41.66667%;
}

.small-pull-5 {
  position: relative;
  left: -41.66667%;
}

.small-offset-4 {
  margin-left: 33.33333%;
}

.small-6 {
  width: 50%;
}

.small-push-6 {
  position: relative;
  left: 50%;
}

.small-pull-6 {
  position: relative;
  left: -50%;
}

.small-offset-5 {
  margin-left: 41.66667%;
}

.small-7 {
  width: 58.33333%;
}

.small-push-7 {
  position: relative;
  left: 58.33333%;
}

.small-pull-7 {
  position: relative;
  left: -58.33333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  width: 66.66667%;
}

.small-push-8 {
  position: relative;
  left: 66.66667%;
}

.small-pull-8 {
  position: relative;
  left: -66.66667%;
}

.small-offset-7 {
  margin-left: 58.33333%;
}

.small-9 {
  width: 75%;
}

.small-push-9 {
  position: relative;
  left: 75%;
}

.small-pull-9 {
  position: relative;
  left: -75%;
}

.small-offset-8 {
  margin-left: 66.66667%;
}

.small-10 {
  width: 83.33333%;
}

.small-push-10 {
  position: relative;
  left: 83.33333%;
}

.small-pull-10 {
  position: relative;
  left: -83.33333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  width: 91.66667%;
}

.small-push-11 {
  position: relative;
  left: 91.66667%;
}

.small-pull-11 {
  position: relative;
  left: -91.66667%;
}

.small-offset-10 {
  margin-left: 83.33333%;
}

.small-12 {
  width: 100%;
}

.small-offset-11 {
  margin-left: 91.66667%;
}

.small-up-1 > .column, .small-up-1 > h1.title-offset, .title-shift .small-up-1 > h1, .small-up-1 > .columns {
  width: 100%;
  float: left;
}

.small-up-1 > .column:nth-of-type(1n), .small-up-1 > h1.title-offset:nth-of-type(1n), .title-shift .small-up-1 > h1:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > h1.title-offset:nth-of-type(1n+1), .title-shift .small-up-1 > h1:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
  clear: both;
}

.small-up-1 > .column:last-child, .small-up-1 > h1.title-offset:last-child, .title-shift .small-up-1 > h1:last-child, .small-up-1 > .columns:last-child {
  float: left;
}

.small-up-2 > .column, .small-up-2 > h1.title-offset, .title-shift .small-up-2 > h1, .small-up-2 > .columns {
  width: 50%;
  float: left;
}

.small-up-2 > .column:nth-of-type(1n), .small-up-2 > h1.title-offset:nth-of-type(1n), .title-shift .small-up-2 > h1:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > h1.title-offset:nth-of-type(2n+1), .title-shift .small-up-2 > h1:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
  clear: both;
}

.small-up-2 > .column:last-child, .small-up-2 > h1.title-offset:last-child, .title-shift .small-up-2 > h1:last-child, .small-up-2 > .columns:last-child {
  float: left;
}

.small-up-3 > .column, .small-up-3 > h1.title-offset, .title-shift .small-up-3 > h1, .small-up-3 > .columns {
  width: 33.33333%;
  float: left;
}

.small-up-3 > .column:nth-of-type(1n), .small-up-3 > h1.title-offset:nth-of-type(1n), .title-shift .small-up-3 > h1:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > h1.title-offset:nth-of-type(3n+1), .title-shift .small-up-3 > h1:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
  clear: both;
}

.small-up-3 > .column:last-child, .small-up-3 > h1.title-offset:last-child, .title-shift .small-up-3 > h1:last-child, .small-up-3 > .columns:last-child {
  float: left;
}

.small-up-4 > .column, .small-up-4 > h1.title-offset, .title-shift .small-up-4 > h1, .small-up-4 > .columns {
  width: 25%;
  float: left;
}

.small-up-4 > .column:nth-of-type(1n), .small-up-4 > h1.title-offset:nth-of-type(1n), .title-shift .small-up-4 > h1:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > h1.title-offset:nth-of-type(4n+1), .title-shift .small-up-4 > h1:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
  clear: both;
}

.small-up-4 > .column:last-child, .small-up-4 > h1.title-offset:last-child, .title-shift .small-up-4 > h1:last-child, .small-up-4 > .columns:last-child {
  float: left;
}

.small-up-5 > .column, .small-up-5 > h1.title-offset, .title-shift .small-up-5 > h1, .small-up-5 > .columns {
  width: 20%;
  float: left;
}

.small-up-5 > .column:nth-of-type(1n), .small-up-5 > h1.title-offset:nth-of-type(1n), .title-shift .small-up-5 > h1:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > h1.title-offset:nth-of-type(5n+1), .title-shift .small-up-5 > h1:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
  clear: both;
}

.small-up-5 > .column:last-child, .small-up-5 > h1.title-offset:last-child, .title-shift .small-up-5 > h1:last-child, .small-up-5 > .columns:last-child {
  float: left;
}

.small-up-6 > .column, .small-up-6 > h1.title-offset, .title-shift .small-up-6 > h1, .small-up-6 > .columns {
  width: 16.66667%;
  float: left;
}

.small-up-6 > .column:nth-of-type(1n), .small-up-6 > h1.title-offset:nth-of-type(1n), .title-shift .small-up-6 > h1:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > h1.title-offset:nth-of-type(6n+1), .title-shift .small-up-6 > h1:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
  clear: both;
}

.small-up-6 > .column:last-child, .small-up-6 > h1.title-offset:last-child, .title-shift .small-up-6 > h1:last-child, .small-up-6 > .columns:last-child {
  float: left;
}

.small-up-7 > .column, .small-up-7 > h1.title-offset, .title-shift .small-up-7 > h1, .small-up-7 > .columns {
  width: 14.28571%;
  float: left;
}

.small-up-7 > .column:nth-of-type(1n), .small-up-7 > h1.title-offset:nth-of-type(1n), .title-shift .small-up-7 > h1:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > h1.title-offset:nth-of-type(7n+1), .title-shift .small-up-7 > h1:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
  clear: both;
}

.small-up-7 > .column:last-child, .small-up-7 > h1.title-offset:last-child, .title-shift .small-up-7 > h1:last-child, .small-up-7 > .columns:last-child {
  float: left;
}

.small-up-8 > .column, .small-up-8 > h1.title-offset, .title-shift .small-up-8 > h1, .small-up-8 > .columns {
  width: 12.5%;
  float: left;
}

.small-up-8 > .column:nth-of-type(1n), .small-up-8 > h1.title-offset:nth-of-type(1n), .title-shift .small-up-8 > h1:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > h1.title-offset:nth-of-type(8n+1), .title-shift .small-up-8 > h1:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
  clear: both;
}

.small-up-8 > .column:last-child, .small-up-8 > h1.title-offset:last-child, .title-shift .small-up-8 > h1:last-child, .small-up-8 > .columns:last-child {
  float: left;
}

.small-collapse > .column, .small-collapse > h1.title-offset, .title-shift .small-collapse > h1, .small-collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}

.small-collapse .row, .small-collapse .footer-wrapper .webform-client-form > div, .footer-wrapper .small-collapse .webform-client-form > div,
.expanded.row .small-collapse.row, .footer-wrapper .webform-client-form > div .small-collapse.row,
.expanded.row .footer-wrapper .webform-client-form > div.small-collapse, .footer-wrapper
.expanded.row .webform-client-form > div.small-collapse, .footer-wrapper .webform-client-form > div .webform-client-form > div.small-collapse {
  margin-left: 0;
  margin-right: 0;
}

.small-uncollapse > .column, .small-uncollapse > h1.title-offset, .title-shift .small-uncollapse > h1, .small-uncollapse > .columns {
  padding-left: 10px;
  padding-right: 10px;
}

.small-centered {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  margin-left: 0;
  margin-right: 0;
  float: left;
}

@media screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%;
  }
  .medium-push-1 {
    position: relative;
    left: 8.33333%;
  }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    width: 16.66667%;
  }
  .medium-push-2 {
    position: relative;
    left: 16.66667%;
  }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%;
  }
  .medium-offset-1 {
    margin-left: 8.33333%;
  }
  .medium-3 {
    width: 25%;
  }
  .medium-push-3 {
    position: relative;
    left: 25%;
  }
  .medium-pull-3 {
    position: relative;
    left: -25%;
  }
  .medium-offset-2 {
    margin-left: 16.66667%;
  }
  .medium-4 {
    width: 33.33333%;
  }
  .medium-push-4 {
    position: relative;
    left: 33.33333%;
  }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    width: 41.66667%;
  }
  .medium-push-5 {
    position: relative;
    left: 41.66667%;
  }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%;
  }
  .medium-offset-4 {
    margin-left: 33.33333%;
  }
  .medium-6, h1.title-offset, .title-shift h1 {
    width: 50%;
  }
  .medium-push-6 {
    position: relative;
    left: 50%;
  }
  .medium-pull-6 {
    position: relative;
    left: -50%;
  }
  .medium-offset-5 {
    margin-left: 41.66667%;
  }
  .medium-7 {
    width: 58.33333%;
  }
  .medium-push-7 {
    position: relative;
    left: 58.33333%;
  }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    width: 66.66667%;
  }
  .medium-push-8 {
    position: relative;
    left: 66.66667%;
  }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%;
  }
  .medium-offset-7 {
    margin-left: 58.33333%;
  }
  .medium-9 {
    width: 75%;
  }
  .medium-push-9 {
    position: relative;
    left: 75%;
  }
  .medium-pull-9 {
    position: relative;
    left: -75%;
  }
  .medium-offset-8 {
    margin-left: 66.66667%;
  }
  .medium-10 {
    width: 83.33333%;
  }
  .medium-push-10 {
    position: relative;
    left: 83.33333%;
  }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    width: 91.66667%;
  }
  .medium-push-11 {
    position: relative;
    left: 91.66667%;
  }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%;
  }
  .medium-offset-10 {
    margin-left: 83.33333%;
  }
  .medium-12 {
    width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.66667%;
  }
  .medium-up-1 > .column, .medium-up-1 > h1.title-offset, .title-shift .medium-up-1 > h1, .medium-up-1 > .columns {
    width: 100%;
    float: left;
  }
  .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > h1.title-offset:nth-of-type(1n), .title-shift .medium-up-1 > h1:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > h1.title-offset:nth-of-type(1n+1), .title-shift .medium-up-1 > h1:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .medium-up-1 > .column:last-child, .medium-up-1 > h1.title-offset:last-child, .title-shift .medium-up-1 > h1:last-child, .medium-up-1 > .columns:last-child {
    float: left;
  }
  .medium-up-2 > .column, .medium-up-2 > h1.title-offset, .title-shift .medium-up-2 > h1, .medium-up-2 > .columns {
    width: 50%;
    float: left;
  }
  .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > h1.title-offset:nth-of-type(1n), .title-shift .medium-up-2 > h1:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > h1.title-offset:nth-of-type(2n+1), .title-shift .medium-up-2 > h1:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .medium-up-2 > .column:last-child, .medium-up-2 > h1.title-offset:last-child, .title-shift .medium-up-2 > h1:last-child, .medium-up-2 > .columns:last-child {
    float: left;
  }
  .medium-up-3 > .column, .medium-up-3 > h1.title-offset, .title-shift .medium-up-3 > h1, .medium-up-3 > .columns {
    width: 33.33333%;
    float: left;
  }
  .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > h1.title-offset:nth-of-type(1n), .title-shift .medium-up-3 > h1:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > h1.title-offset:nth-of-type(3n+1), .title-shift .medium-up-3 > h1:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .medium-up-3 > .column:last-child, .medium-up-3 > h1.title-offset:last-child, .title-shift .medium-up-3 > h1:last-child, .medium-up-3 > .columns:last-child {
    float: left;
  }
  .medium-up-4 > .column, .medium-up-4 > h1.title-offset, .title-shift .medium-up-4 > h1, .medium-up-4 > .columns {
    width: 25%;
    float: left;
  }
  .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > h1.title-offset:nth-of-type(1n), .title-shift .medium-up-4 > h1:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > h1.title-offset:nth-of-type(4n+1), .title-shift .medium-up-4 > h1:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .medium-up-4 > .column:last-child, .medium-up-4 > h1.title-offset:last-child, .title-shift .medium-up-4 > h1:last-child, .medium-up-4 > .columns:last-child {
    float: left;
  }
  .medium-up-5 > .column, .medium-up-5 > h1.title-offset, .title-shift .medium-up-5 > h1, .medium-up-5 > .columns {
    width: 20%;
    float: left;
  }
  .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > h1.title-offset:nth-of-type(1n), .title-shift .medium-up-5 > h1:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > h1.title-offset:nth-of-type(5n+1), .title-shift .medium-up-5 > h1:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .medium-up-5 > .column:last-child, .medium-up-5 > h1.title-offset:last-child, .title-shift .medium-up-5 > h1:last-child, .medium-up-5 > .columns:last-child {
    float: left;
  }
  .medium-up-6 > .column, .medium-up-6 > h1.title-offset, .title-shift .medium-up-6 > h1, .medium-up-6 > .columns {
    width: 16.66667%;
    float: left;
  }
  .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > h1.title-offset:nth-of-type(1n), .title-shift .medium-up-6 > h1:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > h1.title-offset:nth-of-type(6n+1), .title-shift .medium-up-6 > h1:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .medium-up-6 > .column:last-child, .medium-up-6 > h1.title-offset:last-child, .title-shift .medium-up-6 > h1:last-child, .medium-up-6 > .columns:last-child {
    float: left;
  }
  .medium-up-7 > .column, .medium-up-7 > h1.title-offset, .title-shift .medium-up-7 > h1, .medium-up-7 > .columns {
    width: 14.28571%;
    float: left;
  }
  .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > h1.title-offset:nth-of-type(1n), .title-shift .medium-up-7 > h1:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > h1.title-offset:nth-of-type(7n+1), .title-shift .medium-up-7 > h1:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .medium-up-7 > .column:last-child, .medium-up-7 > h1.title-offset:last-child, .title-shift .medium-up-7 > h1:last-child, .medium-up-7 > .columns:last-child {
    float: left;
  }
  .medium-up-8 > .column, .medium-up-8 > h1.title-offset, .title-shift .medium-up-8 > h1, .medium-up-8 > .columns {
    width: 12.5%;
    float: left;
  }
  .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > h1.title-offset:nth-of-type(1n), .title-shift .medium-up-8 > h1:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > h1.title-offset:nth-of-type(8n+1), .title-shift .medium-up-8 > h1:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .medium-up-8 > .column:last-child, .medium-up-8 > h1.title-offset:last-child, .title-shift .medium-up-8 > h1:last-child, .medium-up-8 > .columns:last-child {
    float: left;
  }
  .medium-collapse > .column, .medium-collapse > h1.title-offset, .title-shift .medium-collapse > h1, .medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .medium-collapse .row, .medium-collapse .footer-wrapper .webform-client-form > div, .footer-wrapper .medium-collapse .webform-client-form > div,
  .expanded.row .medium-collapse.row, .footer-wrapper .webform-client-form > div .medium-collapse.row,
  .expanded.row .footer-wrapper .webform-client-form > div.medium-collapse, .footer-wrapper
  .expanded.row .webform-client-form > div.medium-collapse, .footer-wrapper .webform-client-form > div .webform-client-form > div.medium-collapse {
    margin-left: 0;
    margin-right: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > h1.title-offset, .title-shift .medium-uncollapse > h1, .medium-uncollapse > .columns {
    padding-left: 10px;
    padding-right: 10px;
  }
  .medium-centered {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    margin-left: 0;
    margin-right: 0;
    float: left;
  }
}

@media screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%;
  }
  .large-push-1 {
    position: relative;
    left: 8.33333%;
  }
  .large-pull-1 {
    position: relative;
    left: -8.33333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    width: 16.66667%;
  }
  .large-push-2 {
    position: relative;
    left: 16.66667%;
  }
  .large-pull-2 {
    position: relative;
    left: -16.66667%;
  }
  .large-offset-1 {
    margin-left: 8.33333%;
  }
  .large-3 {
    width: 25%;
  }
  .large-push-3 {
    position: relative;
    left: 25%;
  }
  .large-pull-3 {
    position: relative;
    left: -25%;
  }
  .large-offset-2 {
    margin-left: 16.66667%;
  }
  .large-4 {
    width: 33.33333%;
  }
  .large-push-4 {
    position: relative;
    left: 33.33333%;
  }
  .large-pull-4 {
    position: relative;
    left: -33.33333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    width: 41.66667%;
  }
  .large-push-5 {
    position: relative;
    left: 41.66667%;
  }
  .large-pull-5 {
    position: relative;
    left: -41.66667%;
  }
  .large-offset-4 {
    margin-left: 33.33333%;
  }
  .large-6 {
    width: 50%;
  }
  .large-push-6 {
    position: relative;
    left: 50%;
  }
  .large-pull-6 {
    position: relative;
    left: -50%;
  }
  .large-offset-5 {
    margin-left: 41.66667%;
  }
  .large-7 {
    width: 58.33333%;
  }
  .large-push-7 {
    position: relative;
    left: 58.33333%;
  }
  .large-pull-7 {
    position: relative;
    left: -58.33333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8, h1.title-offset, .title-shift h1 {
    width: 66.66667%;
  }
  .large-push-8 {
    position: relative;
    left: 66.66667%;
  }
  .large-pull-8 {
    position: relative;
    left: -66.66667%;
  }
  .large-offset-7 {
    margin-left: 58.33333%;
  }
  .large-9 {
    width: 75%;
  }
  .large-push-9 {
    position: relative;
    left: 75%;
  }
  .large-pull-9 {
    position: relative;
    left: -75%;
  }
  .large-offset-8 {
    margin-left: 66.66667%;
  }
  .large-10 {
    width: 83.33333%;
  }
  .large-push-10 {
    position: relative;
    left: 83.33333%;
  }
  .large-pull-10 {
    position: relative;
    left: -83.33333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    width: 91.66667%;
  }
  .large-push-11 {
    position: relative;
    left: 91.66667%;
  }
  .large-pull-11 {
    position: relative;
    left: -91.66667%;
  }
  .large-offset-10 {
    margin-left: 83.33333%;
  }
  .large-12 {
    width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.66667%;
  }
  .large-up-1 > .column, .large-up-1 > h1.title-offset, .title-shift .large-up-1 > h1, .large-up-1 > .columns {
    width: 100%;
    float: left;
  }
  .large-up-1 > .column:nth-of-type(1n), .large-up-1 > h1.title-offset:nth-of-type(1n), .title-shift .large-up-1 > h1:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > h1.title-offset:nth-of-type(1n+1), .title-shift .large-up-1 > h1:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .large-up-1 > .column:last-child, .large-up-1 > h1.title-offset:last-child, .title-shift .large-up-1 > h1:last-child, .large-up-1 > .columns:last-child {
    float: left;
  }
  .large-up-2 > .column, .large-up-2 > h1.title-offset, .title-shift .large-up-2 > h1, .large-up-2 > .columns {
    width: 50%;
    float: left;
  }
  .large-up-2 > .column:nth-of-type(1n), .large-up-2 > h1.title-offset:nth-of-type(1n), .title-shift .large-up-2 > h1:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > h1.title-offset:nth-of-type(2n+1), .title-shift .large-up-2 > h1:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .large-up-2 > .column:last-child, .large-up-2 > h1.title-offset:last-child, .title-shift .large-up-2 > h1:last-child, .large-up-2 > .columns:last-child {
    float: left;
  }
  .large-up-3 > .column, .large-up-3 > h1.title-offset, .title-shift .large-up-3 > h1, .large-up-3 > .columns {
    width: 33.33333%;
    float: left;
  }
  .large-up-3 > .column:nth-of-type(1n), .large-up-3 > h1.title-offset:nth-of-type(1n), .title-shift .large-up-3 > h1:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > h1.title-offset:nth-of-type(3n+1), .title-shift .large-up-3 > h1:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .large-up-3 > .column:last-child, .large-up-3 > h1.title-offset:last-child, .title-shift .large-up-3 > h1:last-child, .large-up-3 > .columns:last-child {
    float: left;
  }
  .large-up-4 > .column, .large-up-4 > h1.title-offset, .title-shift .large-up-4 > h1, .large-up-4 > .columns {
    width: 25%;
    float: left;
  }
  .large-up-4 > .column:nth-of-type(1n), .large-up-4 > h1.title-offset:nth-of-type(1n), .title-shift .large-up-4 > h1:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > h1.title-offset:nth-of-type(4n+1), .title-shift .large-up-4 > h1:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .large-up-4 > .column:last-child, .large-up-4 > h1.title-offset:last-child, .title-shift .large-up-4 > h1:last-child, .large-up-4 > .columns:last-child {
    float: left;
  }
  .large-up-5 > .column, .large-up-5 > h1.title-offset, .title-shift .large-up-5 > h1, .large-up-5 > .columns {
    width: 20%;
    float: left;
  }
  .large-up-5 > .column:nth-of-type(1n), .large-up-5 > h1.title-offset:nth-of-type(1n), .title-shift .large-up-5 > h1:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > h1.title-offset:nth-of-type(5n+1), .title-shift .large-up-5 > h1:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .large-up-5 > .column:last-child, .large-up-5 > h1.title-offset:last-child, .title-shift .large-up-5 > h1:last-child, .large-up-5 > .columns:last-child {
    float: left;
  }
  .large-up-6 > .column, .large-up-6 > h1.title-offset, .title-shift .large-up-6 > h1, .large-up-6 > .columns {
    width: 16.66667%;
    float: left;
  }
  .large-up-6 > .column:nth-of-type(1n), .large-up-6 > h1.title-offset:nth-of-type(1n), .title-shift .large-up-6 > h1:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > h1.title-offset:nth-of-type(6n+1), .title-shift .large-up-6 > h1:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .large-up-6 > .column:last-child, .large-up-6 > h1.title-offset:last-child, .title-shift .large-up-6 > h1:last-child, .large-up-6 > .columns:last-child {
    float: left;
  }
  .large-up-7 > .column, .large-up-7 > h1.title-offset, .title-shift .large-up-7 > h1, .large-up-7 > .columns {
    width: 14.28571%;
    float: left;
  }
  .large-up-7 > .column:nth-of-type(1n), .large-up-7 > h1.title-offset:nth-of-type(1n), .title-shift .large-up-7 > h1:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > h1.title-offset:nth-of-type(7n+1), .title-shift .large-up-7 > h1:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .large-up-7 > .column:last-child, .large-up-7 > h1.title-offset:last-child, .title-shift .large-up-7 > h1:last-child, .large-up-7 > .columns:last-child {
    float: left;
  }
  .large-up-8 > .column, .large-up-8 > h1.title-offset, .title-shift .large-up-8 > h1, .large-up-8 > .columns {
    width: 12.5%;
    float: left;
  }
  .large-up-8 > .column:nth-of-type(1n), .large-up-8 > h1.title-offset:nth-of-type(1n), .title-shift .large-up-8 > h1:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > h1.title-offset:nth-of-type(8n+1), .title-shift .large-up-8 > h1:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .large-up-8 > .column:last-child, .large-up-8 > h1.title-offset:last-child, .title-shift .large-up-8 > h1:last-child, .large-up-8 > .columns:last-child {
    float: left;
  }
  .large-collapse > .column, .large-collapse > h1.title-offset, .title-shift .large-collapse > h1, .large-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .large-collapse .row, .large-collapse .footer-wrapper .webform-client-form > div, .footer-wrapper .large-collapse .webform-client-form > div,
  .expanded.row .large-collapse.row, .footer-wrapper .webform-client-form > div .large-collapse.row,
  .expanded.row .footer-wrapper .webform-client-form > div.large-collapse, .footer-wrapper
  .expanded.row .webform-client-form > div.large-collapse, .footer-wrapper .webform-client-form > div .webform-client-form > div.large-collapse {
    margin-left: 0;
    margin-right: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > h1.title-offset, .title-shift .large-uncollapse > h1, .large-uncollapse > .columns {
    padding-left: 10px;
    padding-right: 10px;
  }
  .large-centered {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    margin-left: 0;
    margin-right: 0;
    float: left;
  }
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #707070;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #464647;
}

.box--highlight {
  border-color: #007c99;
}

.clearfix:before,
.header:before,
.region-navigation:before,
.region-navigation .block-superfish .sf-menu:before,
.tabs:before {
  content: '';
  display: table;
}

.clearfix:after,
.header:after,
.region-navigation:after,
.region-navigation .block-superfish .sf-menu:after,
.tabs:after {
  content: '';
  display: table;
  clear: both;
}

/********** colorbox **********/
/*** overlay ***/
#cboxOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #000;
  opacity: .76 !important;
}

/*** colorbox ***/
#colorbox {
  z-index: 1000;
  position: fixed;
  visibility: visible !important;
  overflow: visible !important;
  outline: 0;
}

#cboxTopLeft {
  width: 15px;
  height: 15px;
  background: #fff;
}

#cboxTopCenter {
  height: 15px;
  background: #fff;
}

#cboxTopRight {
  width: 15px;
  height: 15px;
  background: #fff;
}

#cboxMiddleLeft {
  width: 15px;
  background: #fff;
}

#cboxMiddleLeft, #cboxBottomLeft {
  clear: left;
}

#cboxContent {
  background: #fff;
}

#cboxMiddleRight {
  width: 15px;
  background: #fff;
}

#cboxLoadedContent {
  margin-bottom: 40px;
}

#cboxTitle {
  color: #fff;
  position: absolute;
  bottom: auto;
  left: 5px;
  padding-top: 10px;
  padding-right: 5px;
}

#cboxTitle p {
  margin: 0;
}

#cboxCurrent {
  color: #fff;
  position: absolute;
  bottom: -40px;
  right: 50px;
}

#cboxNext,
#cboxPrevious {
  display: block;
  text-indent: -999999px;
  height: 0;
  width: 0;
  background: none;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 5px;
  outline: 0;
  z-index: 1001;
}

@media (min-width: 767px) {
  #cboxNext,
  #cboxPrevious {
    bottom: -40px;
  }
}

#cboxPrevious {
  border-right: 26px solid #0072b9;
  border-bottom: 15px solid transparent;
  border-left: 15px solid transparent;
  border-top: 15px solid transparent;
  left: -5px;
}

@media (min-width: 767px) {
  #cboxPrevious {
    border-right: 26px solid #fff;
    left: -51px;
  }
}

#cboxNext {
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 26px solid #0072b9;
  border-top: 15px solid transparent;
  right: -5px;
}

@media (min-width: 767px) {
  #cboxNext {
    border-left: 26px solid #fff;
    right: -51px;
  }
}

#cboxClose {
  display: block;
  text-indent: -999999px;
  background: none;
  position: absolute;
  top: -40px;
  right: 20px;
  color: #fff;
  padding: 0;
  margin: 0;
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 0;
  line-height: 0;
  outline: 0;
}

@media (min-width: 767px) {
  #cboxClose {
    top: 0;
    right: -20px;
  }
}

#cboxClose:before, #cboxClose:after {
  content: '';
  display: block;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

#cboxClose:before {
  height: 30px;
  width: 5px;
  transform: rotate(45deg);
}

#cboxClose:after {
  height: 30px;
  width: 5px;
  transform: rotate(-45deg);
}

/********** modernizr **********/
.no-textshadow #cboxNext,
.no-textshadow #cboxPrevious {
  height: 10px;
  width: 150px;
  text-indent: 0;
}

@media (min-width: 767px) {
  .no-textshadow #cboxPrevious {
    left: -151px;
  }
}

@media (min-width: 767px) {
  .no-textshadow #cboxNext {
    right: -151px;
  }
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

/********** footer **********/
.footer-wrapper {
  color: #fff;
  text-align: center;
  font-weight: lighter;
  padding-top: 25px;
  /*** text and links ***/
  /*** form ***/
}

.footer-wrapper * {
  color: #fff;
  font-weight: lighter;
}

.footer-wrapper a {
  color: #fff;
}

.footer-wrapper a:hover, .footer-wrapper a:active {
  color: #007c99;
}

.footer-wrapper .webform-client-form label {
  text-align: left;
}

.footer-wrapper .webform-client-form input[type='submit'] {
  min-width: 100px;
}

.footer-wrapper .webform-client-form input[type='text'],
.footer-wrapper .webform-client-form input[type='email'],
.footer-wrapper .webform-client-form textarea {
  width: 100%;
  color: #464647 !important;
}

/********** gallery **********/
/*** node ***/
.gallery.layout-center {
  padding-left: 0;
  padding-right: 0;
}

/*** grid ***/
.view-gallery .cboxElement {
  display: inline-block;
  text-align: center;
  color: #464647;
  border: 1px solid #474747;
  line-height: 0;
}

.view-gallery .cboxElement:hover, .view-gallery .cboxElement:active, .view-gallery .cboxElement:visited {
  color: #464647;
}

.view-gallery .cboxElement .gallery-img-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.view-gallery .cboxElement .gallery-img img {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
}

.view-gallery .cboxElement .gallery-text-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}

.view-gallery .cboxElement .gallery-text {
  position: absolute;
  background: #fff;
  background: rgba(255, 255, 255, 0.89);
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
  transition: all .25s;
}

.view-gallery .cboxElement .gallery-text h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
}

.view-gallery .cboxElement .gallery-text p {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
}

.view-gallery .cboxElement:hover .gallery-text, .view-gallery .cboxElement:active .gallery-text {
  transform: translateY(0%);
  transition: all .25s;
}

.header {
  background: #fff;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.25);
  padding-top: 25px;
  padding-bottom: 15px;
}

@media (min-width: 888px) {
  .header {
    padding-top: 55px;
    padding-bottom: 40px;
  }
}

.header__logo {
  float: left;
  margin: 0 10px 0 0;
  padding: 0;
  width: 100px;
  position: relative;
  z-index: 1;
}

@media (min-width: 555px) {
  .header__logo {
    width: 200px;
  }
}

@media (min-width: 888px) {
  .header__logo {
    width: 250px;
  }
}

@media (min-width: 999px) {
  .header__logo {
    width: auto;
  }
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #464647;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  clear: both;
}

/********** custom **********/
/*** navigation region ***/
.region-navigation {
  text-align: right;
  position: relative;
  /* .block-superfish */
}

.region-navigation .block-superfish {
  position: absolute;
  bottom: 0;
  right: 0;
}

/*** fixed header ***/
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
}

/* offset header */
body {
  padding-top: 76px;
}

@media (min-width: 555px) {
  body {
    padding-top: 76px;
  }
}

@media (min-width: 888px) {
  body {
    padding-top: 139px;
  }
}

@media (min-width: 999px) {
  body {
    padding-top: 156px;
  }
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

/********** highlights **********/
/*** homepage ***/
.pane-gallery-panel-pane-6 {
  text-align: center;
}

.pane-gallery-panel-pane-6 h2 {
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

/********** home **********/
.front {
  /* hide page title */
  /* content */
}

.front h1 {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.front strong {
  font-size: 110%;
}

@media (min-width: 767px) {
  .front strong {
    font-size: 125%;
    line-height: 1.75em;
  }
}

.front .pane-1 {
  text-align: center;
}

.front .pane-1 h2 {
  color: #007c99;
  font-weight: lighter;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 0;
  letter-spacing: 2px;
}

@media (min-width: 767px) {
  .front .pane-1 h2 {
    font-size: 2rem;
    line-height: 2.625rem;
    letter-spacing: 3px;
  }
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #464647;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #464647;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

/********** sidebar pop-up images **********/
.paragraphs-item-pop-up-images {
  margin-bottom: 25px;
}

/********** testimonials **********/
/*** layout ***/
@media all and (min-width: 40em) {
  .panels-flexible-region-testimonial-center {
    padding-right: 20px !important;
  }
}

.panels-flexible-testimonial {
  padding-bottom: 25px;
}

/*** styles ***/
.field-name-field-caption {
  font-size: 0.875rem;
}

.paragraphs-item-testimonial .field-name-field-caption {
  display: inline-block;
  max-width: 283px;
}

.field-name-field-pop-up-image a.cboxElement {
  display: inline-block;
  border: 1px solid #474747;
  position: relative;
  overflow: hidden;
  /* icon */
}

.field-name-field-pop-up-image a.cboxElement::before {
  content: '';
  display: block;
  position: absolute;
  background: #fff url(../img/zoom.svg) center center no-repeat;
  background: rgba(255, 255, 255, 0.89) url(../img/zoom.svg) center center no-repeat;
  background-size: 32px;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
  transition: all .25s;
}

.field-name-field-pop-up-image a.cboxElement:hover::before, .field-name-field-pop-up-image a.cboxElement:active::before {
  transform: translateY(0%);
  transition: all .25s;
}

.field-name-field-pop-up-image a.cboxElement img {
  display: block;
  padding: 0;
  margin: 0;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

/********** rotations **********/
.flexslider {
  margin: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  /* nav */
}

.flexslider:hover .flex-direction-nav .flex-prev, .flexslider:active .flex-direction-nav .flex-prev {
  left: 0;
  opacity: 1;
}

.flexslider:hover .flex-direction-nav .flex-next, .flexslider:active .flex-direction-nav .flex-next {
  right: 0;
  opacity: 1;
}

.flexslider .flex-direction-nav {
  position: relative;
}

.flexslider .flex-direction-nav a {
  display: block;
  opacity: 1;
  text-indent: -9999px;
  height: 0;
  width: 0;
}

.flexslider .flex-direction-nav a.flex-prev, .flexslider .flex-direction-nav a.flex-next {
  bottom: 10px;
  position: absolute;
  top: auto;
}

.flexslider .flex-direction-nav a.flex-prev {
  right: auto;
  left: 0;
  border-right: 26px solid #fff;
  border-bottom: 15px solid transparent;
  border-left: 15px solid transparent;
  border-top: 15px solid transparent;
}

.flexslider .flex-direction-nav a.flex-prev:hover, .flexslider .flex-direction-nav a.flex-prev:active {
  border-right: 26px solid #007c99;
}

.flexslider .flex-direction-nav a.flex-next {
  right: 0;
  left: auto;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 26px solid #fff;
  border-top: 15px solid transparent;
}

.flexslider .flex-direction-nav a.flex-next:hover, .flexslider .flex-direction-nav a.flex-next:active {
  border-left: 26px solid #007c99;
}

.flexslider .flex-direction-nav a::before, .flexslider .flex-direction-nav a::after {
  text-indent: -9999px;
}

.flexslider .flex-direction-nav a::before {
  content: '';
}

.flexslider .flex-direction-nav a::after {
  content: '';
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #464647;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

/********** social **********/
#block-menu-menu-social ul.menu {
  padding: 0;
  margin: 0;
  text-align: center;
}

#block-menu-menu-social ul.menu li {
  list-style-type: none;
  list-style-image: none;
  display: inline-block;
}

#block-menu-menu-social ul.menu li a {
  font-size: 0;
  line-height: 0;
  text-decoration: none;
  display: block;
  padding: 10px;
}

#block-menu-menu-social ul.menu li a::before {
  font-size: 50px;
  line-height: 50px;
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #707070 \0/ie;
  background-image: linear-gradient(to top, #707070 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #707070;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #818181;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #464647;
  background: #818181;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #8e8e8e;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #707070 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #707070;
  border-radius: 24px;
  color: #666666;
  background: #959595;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #818181;
  border-color: #3d3d3d;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: #a7a7a7;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

/********** main menu **********/
.region-navigation .sf-menu li.sf-depth-1 a.sf-depth-1 {
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 20px 0 0;
}

@media (min-width: 767px) {
  .region-navigation .sf-menu li.sf-depth-1 a.sf-depth-1 {
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding: 0 20px 0 0;
  }
}

@media (min-width: 1111px) {
  .region-navigation .sf-menu li.sf-depth-1 a.sf-depth-1 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding: 0 40px 0 0;
  }
}

.region-navigation .sf-menu li.sf-depth-1.last a.sf-depth-1 {
  padding-right: 0;
}

/********** mobile **********/
#sidr-wrapper-0 {
  float: right;
}

#sidr-wrapper-0 #sidr-0-button {
  display: inline-block;
  text-decoration: none;
  border: 1px solid #007c99;
  padding: 5px;
}

#sidr-wrapper-0 #sidr-0-button:hover, #sidr-wrapper-0 #sidr-0-button:active {
  background-color: #002933;
  border: 1px solid #002933;
  color: #fff;
}

#sidr-0 {
  /* offset header */
  top: 76px;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #464647;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #707070;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #707070;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #707070;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #707070;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #707070;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #707070;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}
