/********** testimonials **********/

/*** layout ***/
.panels-flexible-region-testimonial-center {
  @media all and (min-width: 40em) {
    padding-right: $zen-gutters/2  !important;
  }
}
.panels-flexible-testimonial {
  padding-bottom: 25px;
}

/*** styles ***/
.field-name-field-caption {
  @include font-size(s);
}

.paragraphs-item-testimonial {
  .field-name-field-caption {
    display: inline-block;
    max-width: 283px;
  }
}

.field-name-field-pop-up-image {

  a.cboxElement {
    display: inline-block;
    border: 1px solid color('gray-black');
    position: relative;
    overflow: hidden;

    /* icon */
    &::before {
      content: '';
      display: block;
      position: absolute;
      background: color('white') url(../img/zoom.svg) center center no-repeat;
      background: rgba( color('white'), .89) url(../img/zoom.svg) center center no-repeat;
      background-size: 32px;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transform: translateY(100%);
      transition: all .25s;
    }

    &:hover,
    &:active {
      &::before {
        transform: translateY(0%);
        transition: all .25s;
      }
    }

    img {
      display: block;
      padding: 0;
      margin: 0;
    }

  }

}
