/********** home **********/
.front {

  /* hide page title */
  h1 {
    @include visually-hidden();
  }

  //subhead
  strong {
    font-size: 110%;
    @include respond-to(m) {
      font-size: 125%;
      line-height: 1.75em;
    }
  }

  /* content */
  .pane-1 {
    text-align: center;

    h2 {
      color: color('green');
      font-weight: lighter;
      @include type-layout(l, 1);
      @include margin-bottom(0, l);
      letter-spacing: 2px;

      @include respond-to(m) {
        @include type-layout(xxl, 1.75);
        letter-spacing: 3px;
      }
    }

  }

}
