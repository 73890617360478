/********** default **********/

/*** padding ***/
.main-content {
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 5px 25px rgba(color('black'), .75);
  @include respond-to(m) {
    padding-top: 40px;
  }
}

.panels-flexible-region-basic_page-center {
  @media all and (min-width: 40em) {
    padding-right: $zen-gutters/2  !important;
  }
}

/*** title ***/
.title-shift {

  h1 {
    @extend .title-offset;
  }

  .panels-flexible-basic_page {
    clear: both;
  }

}
