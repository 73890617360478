/********** colorbox **********/

/*** overlay ***/
#cboxOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: color('black');
  opacity: .76 !important
}

/*** colorbox ***/
#colorbox {
  z-index: 1000;
  position: fixed;
  visibility: visible !important;
  overflow: visible !important;
  outline: 0;
}
#cboxTopLeft {
    width: 15px;
    height: 15px;
    background: color('white');
}
#cboxTopCenter {
  height: 15px;
  background: color('white');
}
#cboxTopRight {
  width: 15px;
  height: 15px;
  background: color('white');
}
#cboxMiddleLeft {
  width: 15px;
  background: color('white');
}
#cboxMiddleLeft, #cboxBottomLeft {
    clear: left;
}
#cboxContent {
    background: color('white');
}
#cboxMiddleRight {
    width: 15px;
    background: color('white');
}
#cboxLoadedContent {
    margin-bottom: 40px;
}
#cboxTitle {
  color: color('white');
  position: absolute;
  bottom: auto;
  left: 5px;
  padding-top: 10px;
  padding-right: 5px;

  p {
    margin: 0;
  }

}
#cboxCurrent {
  color: color('white');
  position: absolute;
  bottom: -40px;
  right: 50px;
}
#cboxNext,
#cboxPrevious {
  display: block;
  text-indent: -999999px;
  height: 0;
  width: 0;
  background: none;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 5px;
  outline: 0;
  z-index: 1001;

  @include respond-to(m) {
    bottom: -40px;
  }

}
#cboxPrevious {
  border-right: 26px solid color('blue');
  border-bottom: 15px solid transparent;
  border-left: 15px solid transparent;
  border-top: 15px solid transparent;
  left: -5px;


  @include respond-to(m) {
    border-right: 26px solid color('white');
    left: -51px;
  }

}
#cboxNext {
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 26px solid color('blue');
  border-top: 15px solid transparent;
  right: -5px;

  @include respond-to(m) {
    border-left: 26px solid color('white');
    right: -51px;
  }

}
#cboxClose{
  display: block;
  text-indent: -999999px;
  background: none;
  position: absolute;
  top: -40px;
  right: 20px;
  color: color('white');
  padding: 0;
  margin: 0;
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 0;
  line-height: 0;
  outline: 0;

  @include respond-to(m) {
    top: 0;
    right: -20px;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    background: color('white');
    position: absolute;
    top: 0;
    left: 0;
  }
  &:before {
    height: 30px;
    width: 5px;
    transform: rotate(45deg);
  }
  &:after {
    height: 30px;
    width: 5px;
    transform: rotate(-45deg);
  }
}

/********** modernizr **********/
.no-textshadow {

  #cboxNext,
  #cboxPrevious {
    height: 10px;
    width: 150px;
    text-indent: 0;
  }

  #cboxPrevious {
    @include respond-to(m) {
      left: -151px;
    }

  }
  #cboxNext {
    @include respond-to(m) {
      right: -151px;
    }
  }

}
